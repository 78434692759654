:root {
  --main-blue:#3E5C76;
  --main-white:#d9dcd6;
  --main-dark:#1f0812;
  --main-gold:#a27a3f;
  background-color:var(--main-white)
  }
  
  @font-face {
  font-family:jolly;
  src:url(./fonts/BestJolly.ttf)
  }
  
  @font-face {
  font-family:baby-moon;
  src:url(./fonts/BabyMoon.ttf)
  }
  
  .App {
  text-align:center;
  background-color:var(--main-white);
  height:100vh
  }
  
  .App-header {
  background-color:#282c34;
  min-height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-size:calc(10px + 2vmin);
  color:#D9DcD6
  }
  
  .App-link {
  color:var(--main-blue)
  }
  
  .carousel {
  width:50%;
  margin:auto
  }
  
  .carousel-item {
  background-color:#98473e;
  height:50vh;
  margin:auto
  }
  
  .carousel-item img {
  height:100%
  }
  
  .header {
  display:flex;
  align-items:center;
  max-height:8%;
  background-color:var(--main-white)
  }
  
  .header h1 {
  margin:30px auto
  }
  
  .header a {
  display:flex;
  align-items:center
  }
  
  .logo {
  max-height:100px;
  position:absolute
  }
  
  @media only screen and (max-width: 680px) {
  .logo {
  max-height:50px
  }
  
  .carousel {
  margin-top:20%
  }
  
  .carousel-item {
  margin-top:30%
  }
  
  .carousel {
  width:80%
  }
  }
  
  @media only screen and (max-width: 360px) {
  .logo {
  display:block;
  position:relative
  }
  }
  
  .title {
  color:#1F0812;
  font-family:baby-moon
  }
  
  .navbar {
  background-color:var(--main-white)
  }
  
  .navbar .active {
  background-color:var(--main-blue)!important;
  color:var(--main-white)!important
  }
  
  .home {
  display:flex;
  flex-direction:column;
  align-items:center;
  min-height:100vh
  }
  
  .about {
  min-height:100vh;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  margin:10% 20%;
  margin-top:100vh;
  background-color:var(--main-white);
  color:var(--main-dark);
  font-size:large
  }
  
  #testimonials {
  min-height:100vh;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  margin:auto 20%
  }
  
  #testimonials .signature {
  margin-left:30%;
  font-weight:700;
  font-size:larger;
  color:var(--main-blue)
  }
  
  .name {
  font-size:large;
  color:var(--main-blue);
  font-family:jolly
  }
  
  h2,h4 {
  font-family:baby-moon
  }
  
  #contact {
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  font-size:large
  }
  
  a:link,a:visited {
  color:var(--main-blue);
  text-decoration:none
  }
  
  #contact a:hover {
  color:var(--main-gold);
  text-decoration:dashed
  }
  
  #contact a:active {
  color:var(--main-gold)
  }
  
  #contact p {
  color:var(--main-blue);
  margin-bottom:-.1rem
  }